import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ApiHook } from '../../hooks/apiHook';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import TablePagination from '../../components/Common/table/TablePagination';
import SubmitButton from '../../components/Common/buttons/SubmitButton';

const InviteGuest = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const partyId = location.state?.selectedParty?.value; // Safeguard with optional chaining
  const [selectedGuestIds, setSelectedGuestIds] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const startPage = 1;
  // api
  const details = ApiHook.CallPartyGuestsById(partyId, itemsPerPage, currentPage);
  const invite = ApiHook.CallInviteGuest();
  // handlers
  const handleItemsPerPageChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1);
  }
  const toNextPage = () => {
    if (currentPage < details?.data?.totalPages) {
      setCurrentPage(details?.data?.currentPage + 1);
    }
  }
  const toLastPage = () => {
    setCurrentPage(details?.data?.totalPages);
  };
  const toPreviousPage = () => {
    if (currentPage > startPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  const toFirstPage = () => {
    setCurrentPage(startPage);
  };
  const handleCheckboxChange = (guestId) => {
    setSelectedGuestIds((prevSelectedGuestIds) => {
      if (prevSelectedGuestIds.includes(guestId)) {
        return prevSelectedGuestIds.filter(id => id !== guestId);
      } else {
        return [...prevSelectedGuestIds, guestId];
      }
    });
  };

  const handleSendInvite = () => {
    const data = {
      partyId,
      guestIdArr: selectedGuestIds
    };
    invite.mutate(data, {
      onSuccess: (res) => {
        if (res.status) {
          toast.success(res?.data);
          setSelectedGuestIds([]);
          queryClient.invalidateQueries({ queryKey: ['party-guest-by-id'] })
        } else {
          toast.error(res?.data?.description);
        }
      }
    });
  };
  
  return (
    <div className="invite-guest">
      <div className="page_head_top">{t('inviteGuest')}</div>

      <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
          <div className="table-responsive min-height-table-2">
            <div className="p-3">
              <table className="table table-manage-invite-party-portal invite-party-portal-processed-Orders">
                <thead>
                  <tr className="th">
                    <th>{t('#')}</th>
                    <th>{t('select')}</th>
                    <th>{t('guestName')}</th>
                    <th>{t('guestEmail')}</th>
                    <th>{t('guestPhone')}</th>
                  </tr>
                </thead>
                <tbody>
                  {details?.data?.guests?.length > 0 ? (
                    details.data.guests.map((guest, index) => (
                      <tr key={guest.id}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedGuestIds.includes(guest.id)}
                            onChange={() => handleCheckboxChange(guest.id)}
                          />
                        </td>
                        <td>{guest.name}</td>
                        <td>{guest.email}</td>
                        <td>{guest.phone}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">{t('noDataFound')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {details?.data?.guests?.length > 0 &&
                <TablePagination
                  startPage={1}
                  currentPage={details?.data?.currentPage}
                  totalPages={details?.data?.totalPages}
                  itemsPerPage={itemsPerPage}
                  handleItemsPerPageChange={handleItemsPerPageChange}
                  toNextPage={toNextPage}
                  toLastPage={toLastPage}
                  toPreviousPage={toPreviousPage}
                  toFirstPage={toFirstPage}
                />
              }
            </div>
          </div>
          <div className="d-flex gap-2 ms-2">
            <SubmitButton
              className="btn mt-1 rounded-1 setup-Party text-white"
              isSubmitting={details?.data?.guests?.length === 0 || selectedGuestIds.length === 0 || invite.isLoading}
              text="sendInvite"
              click={handleSendInvite}
              isLoading={invite.isLoading}
            />
            <Link className="btn mt-1 rounded-1 setup-Party text-white" to='/add-guest' state={{ selectedParty: location.state?.selectedParty }} >{t('addAnotherGuest')}</Link>
            <Link className="btn mt-1 rounded-1 setup-Party text-white btn-back-option" to="/my-party-portal" state={{ selectedParty: location.state?.selectedParty, activeStep: 2 }}>{t('backToPartyPortal')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteGuest;
