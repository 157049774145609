import React, { useState } from 'react';
import { ApiHook } from '../../../hooks/apiHook';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from 'react-bootstrap';
import SubmitButton from '../../Common/buttons/SubmitButton';

const CloseParty = (props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient();
  const [showWarning, setShowWarning] = useState(false);
  const closePartyMutation = ApiHook.CallCloseParty()
  const setWarning = () => {
    if (props.selectedParty !== null) {
      setShowWarning(true);
    } else {
      toast.error(t("selectAParty"));
    }
  }
  const closeParty = () => {
    if (props.selectedParty !== null) {
      const data = { partyId: props.selectedParty.value }
      closePartyMutation.mutate(data, {
        onSuccess: (res) => {
          if (res.status) {
            toast.success(t(res.data));
            setShowWarning(false);
            queryClient.invalidateQueries({ queryKey: ['all-parties'] });
            queryClient.invalidateQueries({ queryKey: ["party-details"] });
          } else {
            toast.error(t(res.data.description));
          }
        }
      })
    } else {
      toast.error(t("selectAParty"));
    }
  }
  return (
    <>
      <div className="table-responsive min-hieght-table-2 mt-3">
        <div className="p-3">
          <div className="regsiter_step_1_view_left_sec_head mb-0">{t('closeTheParty')}</div>
          <span className="inter-line-hori"></span>
          <p className="mt-2">
            {t('closePartyDesc')}
          </p>
          <button
            className="btn mt-2 rounded-3 setup-Party text-white"
            onClick={setWarning}
          >
            {t('closeParty')}
          </button>
        </div>
      </div>
      <Modal show={showWarning} onHide={() => setShowWarning(false)} centered keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t('delete')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("are_you_sure_to_close_party")}?</p>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton className="btn btn-primary" click={closeParty} text="yes" />
          <SubmitButton className="btn btn-secondary" click={() => setShowWarning(false)} text="no" />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CloseParty;
