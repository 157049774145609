import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ViewProductImageModal = (props) => {
    const { t } = useTranslation();
    const handleClose = () => {
        props.setShowImage(false);
        props.setProductData({
            productName: "",
            productImage: ""
        })
    }
    return (
        <Modal show={props.showImage} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.productData.productName || "productImage"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.productData.productImage ? (
                    <img
                        src={props.productData.productImage}
                        alt={props.productData.productName || "Product"}
                        style={{ width: '100%', height: 'auto' }}
                    />
                ) : (
                    <p>{t('noImage')}</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewProductImageModal;