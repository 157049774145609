import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ApiHook } from '../../../hooks/apiHook';
import { useSelector } from 'react-redux';
import CurrencyConverter from '../../../Currency/CurrencyConverter';
import { useTranslation } from 'react-i18next';
import TablePagination from '../../Common/table/TablePagination';

const OrderProduct = () => {
    const location = useLocation();
    const { t } = useTranslation()
    const partyId = location.state?.selectedParty?.partyId ?? JSON.parse(localStorage.getItem('partyId'));
    const selectedParty = location.state?.selectedParty;
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const startPage = 1;
    const currency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    // ---------------------------------- API's -------------------------------------------- //
    const guestOrders = ApiHook.CallGuestOrders(partyId, itemsPerPage, currentPage);
    // handlers
    const handleItemsPerPageChange = (event) => {
        const selectedValue = parseInt(event.target.value);
        setItemsPerPage(selectedValue);
        setCurrentPage(1);
    }
    const toNextPage = () => {
        if (currentPage < guestOrders?.data?.totalPages) {
            setCurrentPage(guestOrders?.data?.currentPage + 1);
        }
    }
    const toLastPage = () => {
        setCurrentPage(guestOrders?.data?.totalPages);
    };
    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };
    const toFirstPage = () => {
        setCurrentPage(startPage);
    };



    return (
        <>
            <div className="page_head_top">{t('orderPartyProduct')}</div>
            <GuestTable
                guestsData={guestOrders?.data}
                partyId={partyId}
                selectedParty={selectedParty}
                currency={currency}
                conversionFactor={conversionFactor}
                t={t}
                handleItemsPerPageChange={handleItemsPerPageChange}
                toNextPage={toNextPage}
                toLastPage={toLastPage}
                toPreviousPage={toPreviousPage}
                toFirstPage={toFirstPage}
                itemsPerPage={itemsPerPage}
            />

        </>
    );
};

const GuestRow = ({ index, guest, partyId, selectedParty, currency, conversionFactor, t }) => (
    <tr key={guest.id}>
        <td>{index + 1}</td>
        <td>{guest?.name}</td>
        <td>{guest?.quantity}</td>
        <td>{currency?.symbolLeft}{" "}{CurrencyConverter(guest?.total, conversionFactor)}</td>
        <td>{guest?.address}</td>
        <td>
            <Link to={'/party-products'} className="btn mt-1 rounded-2 setup-Party text-white" state={{ 'selectedParty': selectedParty, 'guestId': guest.id }}>
                {t('orderProduct')}
            </Link>
        </td>
    </tr>
);

const GuestTable = ({ guestsData, partyId, selectedParty, currency, conversionFactor, t, handleItemsPerPageChange, toNextPage, toLastPage, toPreviousPage, toFirstPage, itemsPerPage }) => (
    <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
            <div className="table-responsive min-height-table-2">
                <div className="p-3">
                    <table className="table table-manage-invite-party-portal invite-party-portal-processed-Orders">
                        <thead>
                            <tr className="th">
                                <th>{t('#')}</th>
                                <th>{t('guestName')}</th>
                                <th>{t('orders')}</th>
                                <th>{t('amount')}</th>
                                <th>{t('address')}</th>
                                <th>{t('order')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {guestsData?.guests?.map((guest, index) => (
                                <GuestRow
                                    key={index}
                                    index={index}
                                    guest={guest}
                                    partyId={partyId}
                                    selectedParty={selectedParty}
                                    currency={currency}
                                    conversionFactor={conversionFactor}
                                    t={t}
                                />
                            ))}
                        </tbody>
                    </table>
                    {guestsData?.guests?.length > 0 &&
                        <TablePagination
                            startPage={1}
                            currentPage={guestsData?.currentPage}
                            totalPages={guestsData?.totalPages}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                            toNextPage={toNextPage}
                            toLastPage={toLastPage}
                            toPreviousPage={toPreviousPage}
                            toFirstPage={toFirstPage}
                            itemsPerPage={itemsPerPage}
                        />
                    }
                    <div className="mb-2 d-flex justify-content-end w-100">
                        <Link
                            to="/my-party-portal"
                            className="btn mt-1 py-2 rounded-1 setup-Party text-white btn-back-option"
                            style={{ marginRight: "7px" }}
                            state={{ 'selectedParty': selectedParty, 'activeStep': 3 }}
                        >
                            {t('back')}
                        </Link>

                        <Link
                            to="/my-party-portal"
                            className="btn mt-1 py-2 rounded-1 setup-Party text-white btn-back-option"
                            state={{ 'selectedParty': selectedParty }}
                        >
                            {t('backToPartyPortal')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default OrderProduct;
