import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const PartyTableContent = (props) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    //  ---------------------------- API --------------------------------
    const deleteHost = ApiHook.CallDeleteHost();
    const deleteGuest = ApiHook.CallDeleteGuest();

    const handleDelete = (id) => {
        if (props.path === '/add-host') {
            let data = { hostId: id }
            deleteHost.mutate(data, {
                onSuccess: (res) => {
                    if (res.status) {
                        toast.success(res.data)
                        queryClient.invalidateQueries({ queryKey: ['host-guests'] });
                    } else {
                        toast.error(res.data.description)
                    }
                }
            })
        } else if (props.path === '/add-guest') {
            let data = { guestId: id }
            deleteGuest.mutate(data, {
                onSuccess: (res) => {
                    if (res.status) {
                        toast.success(res.data)
                        queryClient.invalidateQueries({ queryKey: ['party-guests'] });
                    } else {
                        toast.error(res.data.description)
                    }
                }
            })
        }
    }

    return (
        <>
            <table className="striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{t('firstName')}</th>
                        <th>{t('lastName')}</th>
                        <th>{t('email')}</th>
                        <th>{t('mobile')}</th>
                        <th>{t('address')}</th>
                        <th>{t('action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props?.tableContent?.length > 0 ? (
                        props?.tableContent?.map((row, index) => (
                            <tr key={index}>
                                <td>{((props.currentPage - 1) * 10) + index + 1}</td>
                                <td>{row.name}</td>
                                <td>{row.secondName}</td>
                                <td>{row.email}</td>
                                <td>{row.phone}</td>
                                <td>{row.address}</td>
                                <td>
                                    <NavLink
                                        to={props.path}
                                        state={{ rowData: row }}
                                    >
                                        <button data-bs-toggle="modal" data-bs-target="#leadview" type="button" className="btn btn-primary">{t('edit')}</button>
                                    </NavLink>
                                    <button type="button" onClick={() => handleDelete(row.id)} className="btn-delete">{t('delete')}</button>
                                </td>

                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6">
                                <div className="nodata-table-view">
                                    <div className="nodata-table-view-box">
                                        <div className="nodata-table-view-box-img">
                                            <img src="/images/no-data-image1.jpg" alt="" />
                                        </div>
                                        <div className="nodata-table-view-box-txt">
                                            {t("sorry_no_data_found")}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    )
};

export default PartyTableContent;